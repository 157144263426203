import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faExternalLinkAlt, faPhone, faHeart } from "@fortawesome/free-solid-svg-icons"

import { siteMetadata } from "../../gatsby-config"
import Layout from "../components/layout"
import SEO from "../components/seo"

import React, { Component } from "react"

export default class Page extends Component {
  render() {
    console.log(this.props.data)
    return (
      <Layout container={true} isDark={true} hasDarkColorScheme={true}>
        <SEO title="Impressum" />
        <a name="verantwortliche" style={{ top: 0, marginTop: 0 }}></a>
        <h1 className="header-page">Impressum</h1>

        <div class="has-sidenav">
          <nav>
            <li><a href="#verantwortliche">Verantwortlich</a></li>
            <li><a href="#eu-streitschlichtung">EU-Streitschlichtung</a></li>
            <li><a href="#schlichtungsstelle">Schlichtungs­stelle</a></li>
            <li><Link to="/datenschutz">Datenschutz
              <wbr />
              erklärung <FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon></Link></li>
          </nav>

          <div class="text">
            <h2>Verantwortliche Person</h2>
            <p>
              Dominik Ziegenhagel<br />
              Bayreutherstrasse 21<br />
              01187 Dresden<br />
              <br />
              <b>Dominik Ziegenhagel Media</b> <br />
              Steuer-Nummer 203/292/12298<br />

              <a href={"tel:" + siteMetadata.phone} className="btn btn-primary-outline mt-2"><FontAwesomeIcon
                icon={faPhone}></FontAwesomeIcon>&ensp;{siteMetadata.phone}</a>
              &nbsp;

              <a
                href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%69%6E%66%6F%40%7A%69%65%67%65%6E%68%61%67%65%6C%2E%63%6F%6D"
                className="btn btn-primary-outline mt-2"><FontAwesomeIcon
                icon={faEnvelope}></FontAwesomeIcon>&ensp;info@z<span style={{ display: "none" }}>ifd</span>iegenhagel.com</a>
            </p>
            <a name={"eu-streitschlichtung"}></a>
            <h2>EU-Streitschlichtung</h2>
            <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
              href="https://ec.europa.eu/consumers/odr/" target="_blank"
              rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.
</p>

            <a name={"schlichtungsstelle"}></a>
            <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
              Verbraucherschlichtungsstelle teilzunehmen.</p>


            <h2>Bilder</h2>
            <p>
              <FontAwesomeIcon icon={faHeart} style={{ color: "#f55" }}></FontAwesomeIcon>
              &nbsp; Danke an die Künstler:innen von <a target="_blank" href="https://pexels.com">pexels.com <FontAwesomeIcon
              icon={faExternalLinkAlt}></FontAwesomeIcon></a>.
              <br />
            </p>

            <br />
            <br />
            <div style={{ height: "150px" }}></div>
            <br />
          </div>
        </div>
      </Layout>
    )
  }
}
